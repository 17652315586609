@font-face {
	font-family: 'Lexend';
	font-style: normal;
	font-weight: 100 900;
	font-display: swap;
	src: url(https://fonts.gstatic.com/l/font?kit=wlpwgwvFAVdoq2_f-a4T0WZ9Iwh3KdBx-ZZgT5udM38Vtau2RCv6VsPG7x6RSrYqGG6CmbM7QzZ5nUXcmVl2XOTVciDU-_fNGh7i7-zFeSmNbC_SCLn0QYxhq0RzrQ&skey=77476a9438b362c8&v=v23) format('woff2');
}


@import url("https://p.typekit.net/p.css?s=1&k=cts5iev&ht=tk&f=43128&a=133541619&app=typekit&e=css");

@font-face {
	font-family:"moret";
	src:url("https://use.typekit.net/af/4874f9/00000000000000007735cc79/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/4874f9/00000000000000007735cc79/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/4874f9/00000000000000007735cc79/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
	font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
}

.tk-moret { font-family: "moret",serif; }

@tailwind base;
@tailwind components;
@tailwind utilities;


.grecaptcha-badge {
	visibility: hidden;
}

html,
body {
	padding: 0;
	margin: 0;
}
a {
	color: inherit;
	text-decoration: none;
}
* {
	box-sizing: border-box;
}
  
/* slick slider overrides */

.slick-slider.slick-small > .slick-prev {
	left: 0px !important;
	z-index: 20 !important;
	width: 20px !important;
	height: 20px !important;
}

.slick-slider.slick-small > .slick-next {
	right: 0px !important;
	z-index: 20 !important;
	width: 20px !important;
	height: 20px !important;
}

.slick-small > .slick-prev::before,
.slick-small > .slick-next:before {
	width: 20px !important;
	height: 20px !important;
}

.slick-slider .slick-prev {
	left: 0px !important;
	z-index: 20 !important;
	width: 30px !important;
	height: 30px !important;
}

.slick-slider .slick-next {
	right: 0px !important;
	z-index: 20 !important;
	width: 30px !important;
	height: 30px !important;
}

.slick-prev:before,
.slick-next:before {
	content: '' !important;
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100%;
	height: 30px !important;
	width: 30px !important;
	display: block;
	opacity: 1 !important;
}

@media only screen and (max-width: 600px) {
	.slick-prev:before,
	.slick-next:before {
		content: '' !important;
		background-position: center;
		background-repeat: no-repeat;
		background-size: 80%;
		height: 38px !important;
		width: 38px !important;
		display: block;
		opacity: 1 !important;
	}
	.slick-slider .slick-prev {
		left: 0px !important;
		z-index: 20 !important;
		width: 38px !important;
		height: 38px !important;
	}

	.slick-slider .slick-next {
		right: 0px !important;
		z-index: 20 !important;
		width: 38px !important;
		height: 38px !important;
	}
}

/* .slick-prev:before {
	background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 26.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 40 40' style='enable-background:new 0 0 40 40;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bstroke:%23000000;%7D%0A%3C/style%3E%3Cpath class='st0' d='M8,17.9l17-17c1.2-1.2,3.1-1.2,4.2,0L32,3.7c1.2,1.2,1.2,3.1,0,4.2L20,20L32,32.1c1.2,1.2,1.2,3.1,0,4.2 l-2.8,2.8c-1.2,1.2-3.1,1.2-4.2,0l-17-17C6.8,21,6.8,19.1,8,17.9L8,17.9z'/%3E%3C/svg%3E%0A");
} */

/* .slick-next:before {
	background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 26.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 40 40' style='enable-background:new 0 0 40 40;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bstroke:%23000000;%7D%0A%3C/style%3E%3Cpath class='st0' d='M32,22.1l-17,17c-1.2,1.2-3.1,1.2-4.2,0L8,36.3c-1.2-1.2-1.2-3.1,0-4.2L20,20L8,7.9C6.8,6.8,6.8,4.9,8,3.7 l2.8-2.8c1.2-1.2,3.1-1.2,4.2,0l17,17C33.2,19,33.2,20.9,32,22.1L32,22.1z'/%3E%3C/svg%3E%0A");
} */

.slick-prev:before {
	background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1533_103)'%3E%3Cpath d='M18.65 26.65C19.0167 27.0167 19.4753 27.1913 20.026 27.174C20.5753 27.158 21.0333 26.9667 21.4 26.6C21.7667 26.2333 21.95 25.7667 21.95 25.2C21.95 24.6333 21.7667 24.1667 21.4 23.8L19.6 22H26.05C26.6167 22 27.0833 21.808 27.45 21.424C27.8167 21.0413 28 20.5667 28 20C28 19.4333 27.8087 18.958 27.426 18.574C27.042 18.1913 26.5667 18 26 18H19.6L21.45 16.15C21.8167 15.7833 21.992 15.3247 21.976 14.774C21.9587 14.2247 21.7667 13.7667 21.4 13.4C21.0333 13.0333 20.5667 12.85 20 12.85C19.4333 12.85 18.9667 13.0333 18.6 13.4L13.4 18.6C13.0333 18.9667 12.85 19.4333 12.85 20C12.85 20.5667 13.0333 21.0333 13.4 21.4L18.65 26.65ZM20 40C17.2333 40 14.6333 39.4747 12.2 38.424C9.76667 37.3747 7.65 35.95 5.85 34.15C4.05 32.35 2.62533 30.2333 1.576 27.8C0.525334 25.3667 0 22.7667 0 20C0 17.2333 0.525334 14.6333 1.576 12.2C2.62533 9.76666 4.05 7.65 5.85 5.85C7.65 4.05 9.76667 2.62466 12.2 1.574C14.6333 0.524664 17.2333 -2.86102e-06 20 -2.86102e-06C22.7667 -2.86102e-06 25.3667 0.524664 27.8 1.574C30.2333 2.62466 32.35 4.05 34.15 5.85C35.95 7.65 37.3747 9.76666 38.424 12.2C39.4747 14.6333 40 17.2333 40 20C40 22.7667 39.4747 25.3667 38.424 27.8C37.3747 30.2333 35.95 32.35 34.15 34.15C32.35 35.95 30.2333 37.3747 27.8 38.424C25.3667 39.4747 22.7667 40 20 40Z' fill='%233f2f27'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1533_103'%3E%3Crect width='40' height='40' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
}

.slick-next:before {
	background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1533_106)'%3E%3Cpath d='M21.35 13.35C20.9833 12.9833 20.5247 12.8087 19.974 12.826C19.4247 12.842 18.9667 13.0333 18.6 13.4C18.2333 13.7667 18.05 14.2333 18.05 14.8C18.05 15.3667 18.2333 15.8333 18.6 16.2L20.4 18L13.95 18C13.3833 18 12.9167 18.192 12.55 18.576C12.1833 18.9587 12 19.4333 12 20C12 20.5667 12.1913 21.042 12.574 21.426C12.958 21.8087 13.4333 22 14 22L20.4 22L18.55 23.85C18.1833 24.2167 18.008 24.6753 18.024 25.226C18.0413 25.7753 18.2333 26.2333 18.6 26.6C18.9667 26.9667 19.4333 27.15 20 27.15C20.5667 27.15 21.0333 26.9667 21.4 26.6L26.6 21.4C26.9667 21.0333 27.15 20.5667 27.15 20C27.15 19.4333 26.9667 18.9667 26.6 18.6L21.35 13.35ZM20 -1.74846e-06C22.7667 -1.50659e-06 25.3667 0.525332 27.8 1.576C30.2333 2.62533 32.35 4.05 34.15 5.85C35.95 7.65 37.3747 9.76667 38.424 12.2C39.4747 14.6333 40 17.2333 40 20C40 22.7667 39.4747 25.3667 38.424 27.8C37.3747 30.2333 35.95 32.35 34.15 34.15C32.35 35.95 30.2333 37.3753 27.8 38.426C25.3667 39.4753 22.7667 40 20 40C17.2333 40 14.6333 39.4753 12.2 38.426C9.76667 37.3753 7.65 35.95 5.85 34.15C4.05 32.35 2.62533 30.2333 1.576 27.8C0.525335 25.3667 1.50659e-06 22.7667 1.74846e-06 20C1.99033e-06 17.2333 0.525336 14.6333 1.576 12.2C2.62533 9.76666 4.05 7.64999 5.85 5.85C7.65 4.05 9.76667 2.62533 12.2 1.576C14.6333 0.525331 17.2333 -1.99033e-06 20 -1.74846e-06Z' fill='%233f2f27'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1533_106'%3E%3Crect width='40' height='40' fill='white' transform='translate(40 40) rotate(-180)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
}

.slick-slider .slick-prev:before,
.slick-slider .slick-next:before {
	color: #e4002b !important;
	font-size: 30px;
}

.slick-slider.black .slick-prev:before,
.slick-slider.black .slick-next:before {
	color: #ededed !important;
}

.slick-slider.black .slick-dots li button:before {
	display: none;
}

.slick-slider.black .slick-dots li button {
	border: 1px solid #63746e;
	border-radius: 50%;
	height: 5px;
	width: 5px;
}

.slick-slider.black .slick-dots li.slick-active button {
	background: #63746e;
	border: 1px solid #63746e;
	border-radius: 50%;
	height: 5px;
	width: 5px;
}

.slick-slider .slick-track {
	display: flex !important;
}

.slick-slider .slick-slide {
	height: inherit !important;
}

.slick-slider .slick-slide > div {
	height: 100%;
}

/* Swiper styles */
.swiper-wrapper {
	align-items: center;
}

/* React Datepicker Overrides */
.react-datepicker {
	border: 0 !important;
	padding: 8px 12px !important;
}

.react-datepicker__header {
	padding: 8px 16px 10px !important;
	background-color: white !important;
}

.react-datepicker__current-month {
	color: #2f3337 !important;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
	border-color: #2f3337 !important;
	height: 10px !important;
	width: 10px !important;
}
.react-datepicker__navigation--next,
.react-datepicker__navigation--previous {
	top: 16px !important;
}

.react-datepicker__day {
	border-radius: 50rem !important;
	width: 2rem !important;
	height: 2rem;
	line-height: 2rem !important;
}
.react-datepicker__day--today {
	background-color: white !important;
	border: 1px solid #e4002b !important;
	color: #2f3337 !important;
	font-weight: 400 !important;
}

.react-datepicker__day--keyboard-selected {
	background-color: #e4002b !important;
	color: white !important;
	font-weight: 700 !important;
	border: none !important;
}

/* MAGNIFIED IMAGE PRODUCT PAGE */
.focused-product-image {
	height: auto;
	overflow: hidden;
	width: 100%;
}

.focused-product-image > div {
	height: 100% !important;
	width: auto !important;
}

.focused-product-image .product-image {
	height: 100% !important;
	margin: 0 auto;
	width: auto !important;
	max-height: 300px;
}

@media (min-width: 768px) {
	.focused-product-image {
		height: 550px;
		width: 550px;
	}

	.focused-product-image .product-image {
		max-height: 100%;
	}
}

@media (min-width: 1024px) {
	.focused-product-image {
		height: 600px;
		width: 600px;
	}
}

.magnified-image-container {
	background: rgba(255, 255, 255, 0.95);
	width: 100% !important;
	height: auto !important;
}

.magnified-image {
	background: rgba(255, 255, 255, 0.95);
	min-width: 100% !important;
	max-width: initial !important;
}
.react-toggle {
	touch-action: pan-x;

	display: inline-block;
	position: relative;
	cursor: pointer;
	background-color: transparent;
	border: 0;
	padding: 0;

	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.react-toggle--disabled {
	cursor: not-allowed;
	opacity: 0.5;
	-webkit-transition: opacity 0.25s;
	transition: opacity 0.25s;
}

.react-toggle-track {
	width: 50px;
	height: 24px;
	padding: 0;
	border-radius: 30px;
	background-color: #4d4d4d;
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
	background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
	background-color: #19ab27;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
	background-color: #128d15;
}

.react-toggle-track-check {
	position: absolute;
	width: 14px;
	height: 10px;
	top: 0px;
	bottom: 0px;
	margin-top: auto;
	margin-bottom: auto;
	line-height: 0;
	left: 8px;
	opacity: 0;
	-webkit-transition: opacity 0.25s ease;
	-moz-transition: opacity 0.25s ease;
	transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
	opacity: 1;
	-webkit-transition: opacity 0.25s ease;
	-moz-transition: opacity 0.25s ease;
	transition: opacity 0.25s ease;
}

.react-toggle-track-x {
	position: absolute;
	width: 10px;
	height: 10px;
	top: 0px;
	bottom: 0px;
	margin-top: auto;
	margin-bottom: auto;
	line-height: 0;
	right: 10px;
	opacity: 1;
	-webkit-transition: opacity 0.25s ease;
	-moz-transition: opacity 0.25s ease;
	transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
	opacity: 0;
}

.react-toggle-thumb {
	transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
	position: absolute;
	top: 1px;
	left: 1px;
	width: 22px;
	height: 22px;
	border: 1px solid #4d4d4d;
	border-radius: 50%;
	background-color: #fafafa;

	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;

	-webkit-transition: all 0.25s ease;
	-moz-transition: all 0.25s ease;
	transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
	left: 27px;
	border-color: #19ab27;
}

.react-toggle--focus .react-toggle-thumb {
	-webkit-box-shadow: 0px 0px 3px 2px #0099e0;
	-moz-box-shadow: 0px 0px 3px 2px #0099e0;
	box-shadow: 0px 0px 2px 3px #0099e0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
	-webkit-box-shadow: 0px 0px 5px 5px #0099e0;
	-moz-box-shadow: 0px 0px 5px 5px #0099e0;
	box-shadow: 0px 0px 5px 5px #0099e0;
}
.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

.scrollbar-hide {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
@keyframes slideUp {
	from {
	  transform: translateY(100%);
	}
	to {
	  transform: translateY(0);
	}
  }
  
  .bottom-sheet-enter {
	animation: slideUp 0.3s ease-out forwards;
  }